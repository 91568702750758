<template>
  <ConfigPageLandingPage
    ref="main"
    field-id="field_anonymous_front_page"
  >
    <div
      id="listings"
      ref="listingsSection"
    >
      <TheAppearances id="appearances" />
      <MiscListingsRows />
    </div>
  </ConfigPageLandingPage>
</template>

<script setup lang="ts">
import ConfigPageLandingPage from '~/components/ConfigPageLandingPage.vue';

const main = ref<typeof ConfigPageLandingPage>();
const listingsSection = ref<HTMLElement>();

onMounted(() => {
  if (document) {
    // Create a MutationObserver instance
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const targetElement = document.querySelector('.blades__bladeWrapper:first-child .blade__col:nth-child(2)');
          // Target node and element to append
          const elementToAppend = document.getElementById('appearances');
          if (targetElement) {
            targetElement.appendChild(elementToAppend);
            observer.disconnect();
            break;
          }
        }
      }
    });

    // Options for the observer (monitor child nodes)
    const config = {
      childList: true,
      subtree: true,
    };

    // Start observing the document body
    observer.observe(document.body, config);
  }
});

</script>

<!--@formatter:off-->
<route lang="yaml">
meta:
  layout: FullWidth
</route>
<!--@formatter:on-->

<style scoped lang="scss">
:deep(.blade--0 .blade__col > .line) {
  display: none !important;
}
  
.blades {
  &__blade {
    &:first-child {
      :deep(img) {
        border: solid 1px var(--color--border);
        // because the img has some white on top from crop
        border-top: none;
      }
    }

    &:nth-child(2) {
      :deep(h2) {
        max-width: 14em;
      }
    }

    &:nth-child(3) {
      :deep(h2) {
        max-width: 11em;
      }
    }

    &:nth-child(4) {
      :deep(h2) {
        max-width: 10em;
      }
    }
  }
}

#appearances {
  //margin-top: var(--space-sm);
  //padding-top: var(--space-std);
  //border-top: 1px solid var(--color--brand-primary);

  :deep(h2) {
    font-size: var(--text-heading--h3)
  }

  :deep(img) {
    border: none;
  }
}

</style>
