<template>
  <div>
    <template
      v-for="(listing, k) in listings"
      :key="k"
    >
      <div
        :id="listing.id"
        class="listing"
      >
        <router-link
          class="listing__link"
          :to="listing.href"
        >
          <HeadingHx
            :hx="2"
            class="h2 listing__heading"
          >
            <span class="listing__title">
              {{ listing.title }}
            </span>
            <Icon
              class="listing__linkIcon"
              icon="ph:arrow-right-bold"
            />
          </HeadingHx>
        </router-link>

        <ProductListing
          :bundles-params="listing.bundlesParams"
          :limit="listing.limit || 4"
          :show-filters="false"
          :show-results-count="false"
          :max-cols="4"
        />

        <div class="listing__footer">
          <router-link :to="listing.href">
            {{ listing.linkText }}
          </router-link>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { DrupalJsonApiParams } from 'drupal-jsonapi-params';
import { Icon } from '@iconify/vue';
import { BundlesParams, ProductType } from '~/composables/api/commerce/product';
import { ImageStyle, NonInstrumentProductTypeTerm } from '~/types/enum/enum';
import { UrlString } from '~/types/types';

interface Listing {
  id: string,
  title: string,
  bundlesParams: BundlesParams[],
  imageStyle?: ImageStyle,
  limit?: number,
  href: UrlString,
  linkText: string
}

const listings: Listing[] = [
  {
    id: 'instruments-listing',
    title: 'Instruments',
    bundlesParams: [{
      bundles: [
        ProductType.INSTRUMENT,
      ],
      params: new DrupalJsonApiParams()
        .addFilter('status', '1'),
    }],
    href: '/products/instruments',
    linkText: 'View all instruments',
  },
  {
    id: 'bows-listing',
    title: 'Bows',
    bundlesParams: [{
      bundles: [
        ProductType.BOW,
      ],
      params: new DrupalJsonApiParams()
        .addFilter('status', '1'),
    }],
    href: '/products/bows',
    linkText: 'View all bows',
  },
  // {
  //   id: 'accessories-listing',
  //   title: 'Instrument accessories',
  //   bundlesParams: [{
  //     bundles: [ProductType.NON_INSTRUMENT],
  //     params: new DrupalJsonApiParams()
  //       .addFilter('field_product_type.id', NonInstrumentProductTypeTerm.ACCESSORIES),
  //   }],
  //   imageStyle: ImageStyle.LANDSCAPE_800x600,
  //   href: '/products/accessories',
  //   linkText: 'View all accessories',
  // },
  {
    id: 'luthier-supplies-listing',
    title: 'Luthier supplies',
    bundlesParams: [{
      bundles: [ProductType.NON_INSTRUMENT],
      params: new DrupalJsonApiParams()
        .addFilter('field_product_type.id', NonInstrumentProductTypeTerm.ACCESSORIES, '<>')
        .addFilter('status', '1'),
    }],
    imageStyle: ImageStyle.LANDSCAPE_800x600,
    href: '/products/luthier-supplies',
    linkText: 'View all luthier supplies',
  },
];
</script>

<style scoped lang="scss">
.listing {
  $blade-desktop-breakpoint: 1030px;

  margin: var(--space-lg) auto;
  max-width: var(--width--blade-max--1-col);

  @media(min-width: $blade-desktop-breakpoint) {
    max-width: 61em;

    &__footer {
      display: none
    }
  }

  :deep(ul) {
    margin-top: var(--space-sm);
    --colCount: 2;

    @media(min-width: $blade-desktop-breakpoint) {
      --colCount: 4;
    }
  }

  &__link {
    color: var(--color--copy-primary);
  }

  &__heading {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: var(--space-xxxs);
    transition: gap ease-in-out .2s;

    &:hover,
    &:focus {
      gap: var(--space-xs)
    }
  }

  &__linkIcon {
    font-size: .55em;
  }

  &__footer {
    margin-top: var(--space-sm);
  }
}
</style>
